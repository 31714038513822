.selectWrapper {
  position: relative;
  display: inline-block;

  .select {
    padding: 10px;
    outline: 0;
    background-color: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    width: 150px;
    height: 37px;
    border-radius: 6px;
    border: 1px solid #C5C5C5;
    font-size: 0.875rem;
    color: #656565;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    path {
      fill: #000000;
    }
  }
}
