.dropdown {
  border-bottom: 1px solid #F3F3F3;

  .dropdownHeader {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    height: 63px;
    gap: 10px;

    &.dropdownContentOpen {

      .dropdownLabel {
        color: #510071;
      }

      .iconOpen {
        path {
          fill: #510071;
        }

      }
    }

    .dropdownLabel {
      font-size: 0.875rem;
      color: #656565;
    }

    &:hover {
      .dropdownLabel {
        color: #510071;
      }

      path {
        fill: #510071;
      }
    }
  }

  .dropdownContent {
    .dropdownItem {
      height: 40px;
      padding: 10px 0 10px 40px;
      list-style-type: none;
      cursor: pointer;
      font-size: 0.875rem;
      color: #510071;
    }
  }
}
