.container {
  width: 100%;

  h1 {
    font-weight: 500;
    font-size: 1.75rem;
    color: #1E1E1E;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 20px;
      height: 20px;

      .checked {
        fill: #2b6cde;
      }

    }

    button {
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;
      gap: 10px;

      &:active {
        scale: .9;
      }
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .searchProducts {
    width: 100%;
    background-color: #FFFFFF;
    margin-top: 20px;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      padding: 10px;
    }

    .loading {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .timeline {
      position: relative;
      margin: 20px 0;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      gap: 50px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 8px;
        height: 100%;
        background: linear-gradient(180deg, #2b6cde, #790af4, #FF7F50);
        border-radius: 10px;
        z-index: 1;
        animation: gradientMove 3s infinite linear;
      }

      @media (max-width: 768px) {
        &::before {
          left: 5%;
          transform: translateX(0);
        }
      }
    }

    .timelineItem {
      position: relative;
      width: 45%;
      padding: 20px;
      background: #fff;
      border: 1px solid #2b6cde;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 2;

      &.left {
        margin-left: 0;
        align-self: flex-start;
      }

      &.right {
        margin-left: auto;
        align-self: flex-end;
      }

      @media (max-width: 768px) {
        width: 90%;
        margin-left: 0;
        align-self: center;
      }

      .content {
        position: relative;

        .groupTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h3 {
            margin: 0 0 5px;
            font-size: 18px;
            color: #333;

            @media (max-width: 768px) {
              font-size: 16px;
            }
          }

          .systemImage {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #2b6cde;
            background-color: #fff;

            img {
              width: 24px;
              height: 24px;
            }

            &.error {
              border-color: #CF4655;
            }
          }
        }

        p {
          margin: 10px 0;
          font-size: 14px;
          color: #666;

          @media (max-width: 768px) {
            font-size: 12px;
          }
        }

        .errorEvent {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .groupActions {
            display: flex;
            gap: 12px;
            align-items: center;
          }
        }

        .date {
          font-size: 12px;
          color: #999;
          margin-top: 5px;

          @media (max-width: 768px) {
            font-size: 10px;
          }
        }

        .errorMessage {
          background: #FDECEC;
          border: 1px solid #E57373;
          padding: 10px;
          border-radius: 5px;
          display: flex;
          align-items: center;

          p {
            color: #E57373;
            font-size: 14px;
            margin: 0;

            @media (max-width: 768px) {
              font-size: 12px;
            }

            .errorIcon {
              padding: 8px;
              background-color: #CF4655;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-color: #fff;
        border: 4px solid #2b6cde;
        border-radius: 50%;
        z-index: 3;
      }

      &.error {
        border-color: #CF4655;
      }

      &.error::before {
        border-color: #CF4655;
      }

      &.right::before {
        left: auto;
        right: -10px;
      }

      @media (max-width: 768px) {
        &::before {
          left: 5%;
        }

        &.right::before {
          right: auto;
          left: 5%;
        }
      }
    }
  }
}
