.modalContent {
  border-radius: 1.25rem;
  padding: 0 1.25rem;

  .modalBody {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    padding-inline: 25px;

    .close {
      text-align: right;
      font-size: 25px;
      cursor: pointer;
      color: #000;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 1rem;
        font-size: 0.875rem;
        color: #333;

        input {
          width: 100%;
          padding: 0.5rem;
          margin-top: 0.25rem;
          border: 1px solid #ccc;
          border-radius: 8px;
          font-size: 1rem;
        }

        input:disabled {
          background-color: #f5f5f5;
          color: #999;
        }
      }
    }

    .btnConfirm {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      button {
        font-style: normal;
        font-weight: 500;
        margin-right: 10px;
        padding: 2px;
        font-size: 0.875rem;
        cursor: pointer;
      }

      button:nth-child(1) {
        width: 110px;
        border: 1px solid #dddddd;
        border-radius: 10px;
        margin-right: 10px;
        background: #dddddd;
        color: #333;
      }

      button:nth-child(2) {
        min-width: 110px;
        width: auto;
        border-radius: 10px;
        background: #510071;
        color: #ffffff;
        border: none;
      }
    }
  }
}
