.modalContent {
  max-width: 90% !important;
  width: 800px !important;
  position: relative;

  .modalBody {
    margin: 1rem;
    border-radius: 10px;

    .closeButton {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 1.5rem;
      cursor: pointer;
      background: transparent;
      border: none;
      color: #333;
    }

    .title {
      margin-top: 1.5rem;
      font-size: 1.25rem;
      font-weight: 600;
      color: #333;
    }

    .errorContent {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;

      span {
        font-size: 1rem;
        color: #666;
      }

      p {
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: break-spaces;
        padding: 0.5rem;
        background-color: #f9f9f9;
        border: 1px solid #eee;
        border-radius: 0.5rem;
      }
    }

    span {
      display: flex;
      flex-direction: column;
    }
  }
}