.container {
  width: 100%;

  h1 {
    font-weight: 500;
    font-size: 1.75rem;
    color: #1E1E1E;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .searchProducts {
    width: 100%;
    background-color: #FFFFFF;
    margin-top: 20px;
    padding: 16px;
    border-radius: 10px;

    .alignInput {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 25px;

      .error {
        color: red;
        font-size: 0.75rem;
        width: 300px;
      }

      input {
        flex: 1 1 300px;
        height: 37px;
        outline: 0;
        border: 1px solid #C5C5C5;
        border-radius: 6px;
        padding: 10px;

        @media (max-width: 768px) {
          flex: initial;
        }

        &::placeholder {
          color: #C5C5C5;
        }
      }
    }

    .alignButtonSearch {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      @media (max-width: 768px) {
        justify-content: center;
      }
    }
  }

  .alignButtonCsvRegister {
    display: flex;
    margin: 20px 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
      margin: 5px 0;
    }
  }

  .totalProducts {
    margin-bottom: 15px;

    span {
      color: #656565;
    }
  }

  .tableProducts {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 555px;
    min-height: 555px;

    @media (max-width: 768px) {
      height: auto;
    }


    table {
      width: 100%;
      border-collapse: collapse;
      min-width: 600px;
    }

    th, td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #F3F3F3;
      font-size: 0.875rem;
    }

    td {
      height: 70px;
      color: #656565;
    }

    .title {
      width: 40%;
    }

    .editProducts {
      background-color: transparent;
      border: none;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      outline: 0;

      path:hover {
        fill: #FF6C00;
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 10px;
  }

  .searchProducts .alignInput {
    gap: 15px;

  }

  .tableProducts table {
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .searchProducts {
    padding: 10px;

    .alignInput {
      flex-direction: column;
      align-items: stretch;
      gap: 10px;

      input {
        width: 100%;
      }
    }

    .alignButtonSearch {
      justify-content: center;
    }
  }

  .alignButtonCsvRegister {
    flex-direction: column;
    align-items: stretch;

    button {
      width: 100%;
    }
  }

  .tableProducts {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .searchProducts {
    .alignInput input {
      font-size: 0.875rem;
    }

    .alignButtonSearch button {
      width: 100%;
      font-size: 0.875rem;
    }
  }

  .tableProducts th, .tableProducts td {
    font-size: 0.75rem;
    padding: 5px;
  }
}

.alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dNone {
  display: none;
}